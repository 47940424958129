import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import {
  companyName,
  companyPhoneNumberFormatted,
  companyPhoneNumberFull,
} from "@app/constants/constants";
import { UtilsService } from "@shop-services/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoToolbarComponent {
  screen$: Observable<MediaChange[]>;

  companyName: string;

  phoneNumber: string;
  phoneNumberFull: string;

  constructor(
    public mediaObserver: MediaObserver,
    public utils: UtilsService,
    private ref: ChangeDetectorRef
  ) {
    this.companyName = companyName;

    this.phoneNumber = companyPhoneNumberFormatted;
    this.phoneNumberFull = companyPhoneNumberFull;

    this.screen$ = this.mediaObserver.asObservable();
    this.screen$.subscribe(() => this.ref.markForCheck());
  }

  get isTablet(): boolean {
    return this.utils.isTablet;
  }
}
