import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import {
  companyName,
  companyPhoneNumberFull,
  companyPhoneNumberFullFormatted,
  Theme,
} from "@app/constants/constants";
import * as fromStore from "@app/store";
import { Store } from "@ngrx/store";
import { ThemeService } from "@shop-services/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  companyName: string;

  phoneNumber: string;
  phoneNumberFull: string;

  constructor(
    public mediaObserver: MediaObserver,
    private theme: ThemeService,
    private store: Store<fromStore.ShopState>
  ) {
    this.companyName = companyName;

    this.phoneNumber = companyPhoneNumberFullFormatted;
    this.phoneNumberFull = companyPhoneNumberFull;
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  switchDartkTheme(bool): void {
    const theme: Theme = bool ? Theme.dark : Theme.light;
    this.store.dispatch(new fromStore.SetTheme(theme));
  }

  get isDarkTheme$(): Observable<boolean> {
    return this.theme.isDark$;
  }
}
