<ng-container
  *ngIf="mediaObserver.isActive('lt-md') || isTablet; then mobile; else pc"
>
</ng-container>

<!-- PC TEMPLATE-->
<ng-template #pc>
  <div class="head-wrapper">
    <div class="logo-wrapper">
      <img
        routerLink="/"
        src="assets/logo/Logo_1.svg"
        alt="Логотип"
        class="logo"
        [title]="companyName"
      />
    </div>

    <div class="info-wrapper">
      <h3 class="phone mat-title">
        <a [href]="'tel:' + phoneNumberFull">{{ phoneNumber }}</a>
      </h3>
    </div>
  </div>
</ng-template>

<!-- Mobile TEMPLATE-->
<ng-template #mobile>
  <mat-toolbar color="primary" style="height: 56px">
    <mat-icon class="menu-icon" (click)="utils.openMenu()">menu</mat-icon>
    <img src="assets/logo/logo1.png" alt="logo" class="logo" routerLink="/" />
  </mat-toolbar>
</ng-template>
